function getMonthString(date){
	const year = date.getFullYear();
	const month = date.getMonth()+1;
	return `${year}-${month<10?('0'+month):month}`;
}
function formatDate(timestamp){
	const date = new Date(timestamp);
	let m = date.getMinutes();
	m = (m < 10 ? '0' : '') + m;
	let h = date.getHours();
	h = (h < 10 ? '0' : '') + h;
	const D = date.getDate();
	const M = date.getMonth() + 1;
	const YYYY = date.getFullYear();
	// const YY = YYYY.toString().substring(2);
	const DD = (D < 10 ? '0' : '') + D;
	const MM = (M < 10 ? '0' : '') + M;
	return `${YYYY}-${MM}-${DD} ${h}:${m}`;
}

export {
	getMonthString,
	formatDate,
};
