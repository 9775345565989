import { useState, useEffect } from "react";
import { sendSMSLoginCode, verifyPhoneCodeAndLogin } from "../adminFirebaseActions.mjs";
const RECAPTCHA_CONTAINER_ID = "8c7cef44-044e-4a18-bb78-c51f4c50deea";

function MFALoginPage({resolver}) {
	const [phoneCode, setPhoneCode] = useState("");
	const [verificationId, setVerificationId] = useState("");
	useEffect(() => {
		sendSMSLoginCode(resolver, RECAPTCHA_CONTAINER_ID)
		.then(function(vId){
			setVerificationId(vId);
		})
	}, [resolver]);

	function submitCode(e){
		e.preventDefault();
		verifyPhoneCodeAndLogin(resolver, verificationId, phoneCode)
		.then(function(){
			window.location.reload();
		})
	}
	return (
		<div className="MFAModal">
			<div className="modal">
				<div>A code has been sent to: {resolver?.hints?.[0]?.phoneNumber}</div>
				<form onSubmit={submitCode}>
					<input onChange={(e)=>{setPhoneCode(e.target.value);}} value={phoneCode} required />
					<br />
					<input type="submit" value="Submit code"/>
				</form>
			</div>
		</div>
	);
}
export default MFALoginPage;
