import { useRouteError, isRouteErrorResponse, useNavigate } from "react-router-dom";

function ErrorPage() {
	const error = useRouteError();
	const navigate = useNavigate();
	console.log('error', error, {...error})
	if (isRouteErrorResponse(error)) {
		return (
			<div>
				<h1>Oops!</h1>
				<h2>{error.status}</h2>
				<p>{error.statusText}</p>
				{error.data?.message && <p>{error.data.message}</p>}
			</div>
		);
	} else if (error.name==="FirebaseError" && error.code==="functions/unauthenticated") {
		if(error.details?.reason==="auth/multi-factor-auth-required"){
			setTimeout(()=>navigate("/mfa"), 100);
		}
		return <div>{error.message?error.message:"Unexpected unauthenticated error without message"}</div>;
	} else if (error.name==="FirebaseError" && error.code==="functions/permission-denied") {
		return <div>You must login</div>;
	} else {
		return <div>Unexpected error</div>;
	}
}
export default ErrorPage;
