import { useLoaderData, useNavigate} from "react-router-dom";
import { getMonthString } from '../utils.mjs';
import OrganizationMember from '../components/OrganizationMember.jsx';
import { getLoginToken, editUserStatus } from '../adminFirebaseActions.mjs';
import "./UsersPage.scss";
function UsersPage() {
	const {users, organizations} = useLoaderData();
	const navigate = useNavigate();

	/* Merge data */
	const userToOrgMap = {};
	organizations.forEach((org)=>{
		const orgId = org.id;
		const orgData = org.data;
		for(const userId in orgData.roles){
			if(!userToOrgMap[userId]) userToOrgMap[userId] = [];
			userToOrgMap[userId].push({
				"id": orgId,
				"name": orgData.name,
				"role": orgData.roles[userId].role,
			});
		}
	});
	const now = new Date();
	const prevMonth = new Date(now - ((now.getDate()+1)*24*60*60*1000));
	const currentMonthString = getMonthString(now);
	const previousMonthString = getMonthString(prevMonth);
	const loginAs = function (userId) {
		getLoginToken(userId)
		.then(function(dt){
			window.open(dt.url + '/adminAuth/' + dt.token, 'c2fa9a2db6924ef790a6bc3a3749152b');
		})
	}

	function onChangeStatus(user){
		const changeString = user.data?.removedAccess?"enable":"disable";
		if(window.confirm(`Are you sure you want to ${changeString} ${user.data.email}?`)){
			editUserStatus(user.id, !user.data?.removedAccess)
			.then(()=>window.location.reload())
		}
	}

	return (
		<div className="UsersPage">
			{users===undefined && "Loading..."}
			<table style={{width: "100%"}}>
				<thead>
					<tr>
						<th className="email">Email</th>
						<th className="campaignCounter">Campaigns</th>
						<th className="totalResponses">Total Responses</th>
						<th className="currentMonthResponses">Previous Month Responses</th>
						<th className="previousMonthResponses">Current Month Responses</th>
						<th className="activeStatus">Active</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(users) && users.map((user) => {
						return (
							<tr key={user.id} className={user.data?.removedAccess?"isRemovedAccess":""}>
								<td className="email">
									{user?.data?.adminRead && <span className="isAdmin" title="This user is an admin">*</span>}
									<span onClick={()=>loginAs(user.id)} className="loginAs" title={"Login as: " + user.data.email}>🔑</span>
									<span onClick={()=>navigate("/user/" + user.id)} className="link">{user.data.email}</span>
									{userToOrgMap[user.id] && userToOrgMap[user.id].map((org)=>{
										return (
											<div key={org.id} className="userOrg">
												<OrganizationMember id={org.id} name={org.name} role={org.role} clickable={()=>navigate("/organization/" + org.id)}/>
											</div>
										);
									})}
								</td>
								<td className="campaignCounter">{user.data.campaignCounter?user.data.campaignCounter:0}</td>
								<td className="totalResponses">{user.data.totalResponses?user.data.totalResponses:0}</td>
								<td className="previousMonthResponses">{user.data?.monthlyResponses?.[previousMonthString]?user.data?.monthlyResponses?.[previousMonthString]:0}</td>
								<td className="currentMonthResponses">{user.data?.monthlyResponses?.[currentMonthString]?user.data?.monthlyResponses?.[currentMonthString]:0}</td>
								<td className="activeStatus">
									{user.data?.removedAccess?'No':'Yes'}
									<br/>
									<button onClick={()=>onChangeStatus(user)}>{user.data?.removedAccess?'activate':'deactivate'}</button>
								</td>
							</tr>
						)
					})}
				</tbody>
				<tfoot>
					<tr>
						<td><b>Total</b></td>
						<td className="campaignCounter">{Array.isArray(users) && users.reduce((total, user)=>{return total + (user.data.campaignCounter?user.data.campaignCounter:0)}, 0)}</td>
						<td className="totalResponses">{Array.isArray(users) && users.reduce((total, user)=>{return total + (user.data.totalResponses?user.data.totalResponses:0)}, 0)}</td>
						<td className="currentMonthResponses">{Array.isArray(users) && users.reduce((total, user)=>{return total + (user.data?.monthlyResponses?.[previousMonthString]?user.data?.monthlyResponses?.[previousMonthString]:0)}, 0)}</td>
						<td className="previousMonthResponses">{Array.isArray(users) && users.reduce((total, user)=>{return total + (user.data?.monthlyResponses?.[currentMonthString]?user.data?.monthlyResponses?.[currentMonthString]:0)}, 0)}</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
}

export default UsersPage;
