import { useState, useEffect, StrictMode } from "react";
import UsersPage from './pages/UsersPage';
import UserPage from './pages/UserPage';
import OrganizationPage from './pages/OrganizationPage';
import LogsPage from './pages/LogsPage';
import MFAPage from './pages/MFAPage';
import MFALoginPage from './pages/MFALoginPage';
import ErrorPage from './pages/ErrorPage';
import { showLogin, logout, on, off, getAllUsers, getAllCampaigns, getAllOrganizations, getUserOrganizations, getAllOrganizationCampaigns, getLogs } from './adminFirebaseActions.mjs';
import "./App.scss";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(null);
	const [MFAResolver, setMFAResolver] = useState(null);
	const router = createBrowserRouter([
		{
			path: "/",
			loader: async({request, params}) => {
				if(!isLoggedIn){
					// see: https://reactrouter.com/en/6.10.0/fetch/redirect
					return new Error("This error should not be seen in the router");
				}
				const [{organizations}, {users}] = await Promise.all([getAllOrganizations(), getAllUsers()]);
				return {users, organizations};
			},
			element: <UsersPage />,
			errorElement: <ErrorPage />,
		},
		{
			path: "/user/:userId",
			loader: async({request, params}) => {
				const [{organizations}, {campaigns}] = await Promise.all([getUserOrganizations(params.userId), getAllCampaigns(params.userId)]);
				return {campaigns, organizations};
			},
			element: <UserPage />,
			errorElement: <ErrorPage />,
		},
		{
			path: "/organization/:organizationId",
			loader: async({request, params}) => {
				const {campaigns} = await getAllOrganizationCampaigns(params.organizationId);
				return {campaigns};
			},
			element: <OrganizationPage />,
			errorElement: <ErrorPage />,
		},
		{
			path: "/logs/:campaignId",
			loader: async({request, params}) => {
				const {logs} = await getLogs(params.campaignId);
				return {logs};
			},
			element: <LogsPage />,
			errorElement: <ErrorPage />,
		},
		{
			path: "/mfa",
			element: <MFAPage />,
		},
	]);

	useEffect(() => {
		const onLogin = function(user){
			setIsLoggedIn(true);
		};
		const onLogout = function(){
			setIsLoggedIn(false);
		};
		const onTwoFactor = function(resolver){
			setMFAResolver(resolver);
		};
		on("login", onLogin);
		on("logout", onLogout);
		on("two-factor", onTwoFactor);
		return () => {
			off("login", onLogin);
			off("logout", onLogout);
			off("two-factor", onTwoFactor);
		}
	}, []);

	return (
		<StrictMode>
			{isLoggedIn===false && <button onClick={showLogin}>Login</button>}
			{isLoggedIn===true && <button onClick={logout} style={{float:"right"}}>Logout</button>}
			{isLoggedIn===true && <RouterProvider router={router} />}
			{MFAResolver && <MFALoginPage resolver={MFAResolver}/>}
		</StrictMode>
	);
}
export default App;
