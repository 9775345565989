import "./OrganizationMember.scss";
function OrganizationMember({id, name, role, clickable}) {
	const color = id.split('').reduce((a,b)=>((a*b.charCodeAt())%16777215), 1).toString(16).padStart(6, '0').substring(0,6);
	return (
		<div className={"OrganizationMember " + (clickable?"clickable":"")} onClick={typeof clickable === "function"?clickable:undefined} style={{"borderColor": "#" + color}}>
			<span className={"bullet " + role} title={role + " of " + name}></span>
			<span>{name}</span>
		</div>
	);
}

export default OrganizationMember;