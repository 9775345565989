import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, connectAuthEmulator, multiFactor, getMultiFactorResolver, RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import { getFirestore, doc, getDoc, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

const provider = new GoogleAuthProvider();
const firebaseConfig = {
	apiKey: "AIzaSyBD_NtznWHLPV4JiSVbPLz8k6LBfiCNG90",
	authDomain: "morepolls-1cc1e.firebaseapp.com",
	projectId: "morepolls-1cc1e",
	storageBucket: "morepolls-1cc1e.appspot.com",
	messagingSenderId: "817940652447",
	appId: "1:817940652447:web:4c68281890f0d7c8a2ddaa"
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
if(process.env.NODE_ENV === 'development') connectAuthEmulator(auth, "http://localhost:28754");

const db = getFirestore(firebaseApp);
if(process.env.NODE_ENV === 'development') connectFirestoreEmulator(db, "localhost", 29479);

const functions = getFunctions(firebaseApp, "europe-west1");
if(process.env.NODE_ENV === 'development') connectFunctionsEmulator(functions, 'localhost', 28764);

let currentUser = null;

function showLogin() {
	signInWithPopup(auth, provider)
	.then((result) => {
		// console.log('result', result)
	})
	.catch((error) => {
		console.log('login error', error, {...error})
		if(error.code==="auth/multi-factor-auth-required"){
			const resolver = getMultiFactorResolver(auth, error);
			cbs["two-factor"].forEach((cb)=>cb(resolver));
		}
	});
}

function logout() {
	auth.signOut();
}

const cbs = {"login":[], "logout":[], "two-factor":[]};
function on(action, cb) {
	cbs[action].push(cb);
}
function off(action, cb) {
	const index = cbs[action].indexOf(cb);
	if(index>=0) cbs[action].splice(index, 1);
}

const shortTermCache = new Map();
function getShortTermCahe(name, key) {
	if(!shortTermCache.has(name)){
		shortTermCache.set(name, new Map());
	}
	return shortTermCache.get(name).get(key);
}
function setShortTermCahe(name, key, promise) {
	if(!shortTermCache.has(name)){
		shortTermCache.set(name, new Map());
	}
	shortTermCache.get(name).set(key, promise);
	setTimeout(()=>{
		const c = getShortTermCahe(name, key);
		if(c===promise) {
			shortTermCache.get(name).delete(key);
		}
	}, 500);
}
function getCachedOrRun(name, key, run) {
	const c = getShortTermCahe(name, key);
	if(c) return c;
	const promise = run();
	setShortTermCahe(name, key, promise);
	return promise;
}

onAuthStateChanged(auth, (user) => {
	if (user) {
		cbs["login"].forEach((cb)=>cb(user));
		currentUser = user;
	} else {
		// console.log('User is signed out');
		cbs["logout"].forEach((cb)=>cb());
	}
});

async function getUser(userId){
	if(!userId) return undefined;
	const userRef = doc(db, "users", userId);
	const userDoc = await getDoc(userRef);
	if (userDoc.exists()) {
		const settings = userDoc.data();
		return {settings};
	}
	else {
		return undefined;
	}
}

async function getAllUsers() {
	return getCachedOrRun('getAllCampaigns', null, ()=>{
		return httpsCallable(functions, `app/getAllUsers`)().then((dt)=>dt.data);
	});
}
async function editUserStatus(userId, removedAccess) {
	return httpsCallable(functions, `app/modify/users/${userId}`)({removedAccess}).then((dt)=>dt.data);
}
async function getAllCampaigns(userId) {
	return getCachedOrRun('getAllCampaigns', userId, ()=>{
		return httpsCallable(functions, `app/getAllCampaigns`)({userId}).then((dt)=>dt.data);
	});
}
async function getAllOrganizations(userId) {
	return getCachedOrRun('getAllOrganizations', userId, ()=>{
		return httpsCallable(functions, `app/getAllOrganizations`)({userId}).then((dt)=>dt.data);
	});
}
async function getUserOrganizations(userId) {
	return getCachedOrRun('getUserOrganizations', userId, ()=>{
		return httpsCallable(functions, `app/getUserOrganizations`)({userId}).then((dt)=>dt.data);
	});
}
async function getAllOrganizationCampaigns(organizationId) {
	return getCachedOrRun('getAllOrganizationCampaigns', organizationId, ()=>{
		return httpsCallable(functions, `app/getAllOrganizationCampaigns`)({organizationId}).then((dt)=>dt.data);
	});
}
async function getLogs(campaignId) {
	return getCachedOrRun('getLogs', campaignId, ()=>{
		return httpsCallable(functions, `app/getLogs`)({campaignId}).then((dt)=>dt.data);
	});
}
async function getLoginToken(userId) {
	const dt = await httpsCallable(functions, `app/getLoginToken/${userId}`)();
	return dt.data;
}
const mfaDisplayName = 'My personal phone number';
async function sendPhoneVerificationCode(recaptchaContainerId, phoneNumber) {
	const recaptchaVerifier = new RecaptchaVerifier(recaptchaContainerId, {"size": "invisible",}, auth);
	const multiFactorSession = await multiFactor(currentUser).getSession();
	// Specify the phone number and pass the MFA session.
	const phoneInfoOptions = {
		phoneNumber: phoneNumber,
		session: multiFactorSession
	};

	const phoneAuthProvider = new PhoneAuthProvider(auth);

	// Send SMS verification code.
	return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
}
async function verifyPhoneCode(verificationId, verificationCode) {
	// Ask user for the verification code. Then:
	const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
	const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

	// Complete enrollment.
	return multiFactor(currentUser).enroll(multiFactorAssertion, mfaDisplayName);
}

async function sendSMSLoginCode(resolver, recaptchaContainerId) {
	const recaptchaVerifier = new RecaptchaVerifier(recaptchaContainerId, {"size": "invisible",}, auth);
	const h = resolver.hints.find((h)=>h.factorId === PhoneMultiFactorGenerator.FACTOR_ID);
	if (!h) return undefined;
	const phoneInfoOptions = {
		multiFactorHint: h,
		session: resolver.session
	};
	const phoneAuthProvider = new PhoneAuthProvider(auth);
	return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
}
async function verifyPhoneCodeAndLogin(resolver, verificationId, verificationCode) {
	const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
	const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
	return resolver.resolveSignIn(multiFactorAssertion)
}

export {
	getAllUsers,
	editUserStatus,
	getAllCampaigns,
	getAllOrganizations,
	getUserOrganizations,
	getAllOrganizationCampaigns,
	getLogs,
	getLoginToken,

	showLogin,
	logout,
	on,
	off,
	getUser,

	sendPhoneVerificationCode,
	verifyPhoneCode,
	sendSMSLoginCode,
	verifyPhoneCodeAndLogin,
};
