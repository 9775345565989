import { formatDate } from '../utils.mjs';
import { useLoaderData } from "react-router-dom";

import "./LogsPage.scss";

function getColumnsMap(logs){
	const cs = new Map();
	[
		"time",
		"type",
		"userId",
		"deviceId",
		"uuid",
		"campaignId",
	].forEach((key)=>add(key));

	logs.forEach(({data})=>{
		Object.keys(data).forEach((key)=>add(key));
	});
	return cs;
	function add(key){
		if(!cs.has(key)) cs.set(key, {
			'order':cs.size,
			'uniqueValues':[],
		});
	}
}

function LogsPage() {
	const {logs} = useLoaderData();
	console.log('logs', logs)

	// Extract columns
	const cs = getColumnsMap(logs);
	const columns = [...Array.from(cs.keys())];

	return (
		<div className="LogsPage">
			{logs===undefined && "Loading..."}
			<table style={{width: "100%"}}>
				<thead>
					<tr>
						{columns.map((key)=>{
							return <th key={key}>{key}</th>;
						})}
					</tr>
				</thead>
				<tbody>
					{logs.map((log)=>{
						return (<tr key={log.id} className={(log.data["type"]==="error")?"isError":""}>
							{columns.map((key)=>{
								return (<td key={key}>
									{(key==="time")?formatDate(log.data[key]):log.data[key]}
								</td>);
							})}
						</tr>);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default LogsPage;