import { useState, useMemo } from "react";
import { getMonthString, formatDate } from '../utils.mjs';
import { useLoaderData, useNavigate, useLocation } from "react-router-dom";
import OrganizationMember from '../components/OrganizationMember.jsx';

import "./UserPage.scss";
function UserPage() {
	const {pathname} = useLocation();
	const {campaigns, organizations} = useLoaderData();
	const navigate = useNavigate();
	const [sortFunction, setSortFunction] = useState("sortByCreationTime");

	const now = new Date();
	const prevMonth = new Date(now - ((now.getDate()+1)*24*60*60*1000));
	const currentMonthString = getMonthString(now);
	const previousMonthString = getMonthString(prevMonth);

	const sortFunctions = useMemo(()=>{
		return {
			"sortByCampaignName": function (a, b){
				const aS = a?.data?.name||"";
				const bS = b?.data?.name||"";
				return bS.localeCompare(aS, undefined, {"sensitivity": "base", "ignorePunctuation": true});
			},
			"sortByTotalResponses": function (a, b){
				const aS = a?.data?.totalResponses||0;
				const bS = b?.data?.totalResponses||0;
				return bS - aS;
			},
			"sortByPreviousMonthResponses": function (a, b){
				const aS = a?.data?.monthlyResponses?.[previousMonthString]||0;
				const bS = b?.data?.monthlyResponses?.[previousMonthString]||0;
				return bS - aS;
			},
			"sortByCurrentMonthResponses": function (a, b){
				const aS = a?.data?.monthlyResponses?.[currentMonthString]||0;
				const bS = b?.data?.monthlyResponses?.[currentMonthString]||0;
				return bS - aS;
			},
			"sortByCreationTime": function (a, b){
				const aS = a?.data?.timestamp?._seconds||0;
				const bS = b?.data?.timestamp?._seconds||0;
				return bS - aS;
			},
			"sortByEndTime": function (a, b){
				const aS = a?.data?.endDate||0;
				const bS = b?.data?.endDate||0;
				return bS - aS;
			},
		}
	}, [previousMonthString, currentMonthString]);
	const sortedCampaigns = Array.isArray(campaigns)?[...campaigns].sort(sortFunctions[sortFunction]):[];

	// Generate the revers order functions
	for(const funcName in sortFunctions){
		sortFunctions["-"+funcName] = (a, b) => -sortFunctions[funcName](a,b);
	}
	function changeSortFunction(func) {
		if(sortFunction!==func){
			setSortFunction(func);
		}else{
			setSortFunction("-"+func);
		}
	}

	return (
		<div className="UserPage">
			{campaigns===undefined && "Loading..."}
			<div>
				<a href="/">Users</a>
				{Array.isArray(organizations) && organizations.map((org)=>{
					return <OrganizationMember key={org.id} id={org.id} name={org.data.name} role={org.data.role} clickable={()=>navigate("/organization/" + org.id, {"state":{"from":pathname}})}/>;
				})}
			</div>
			<table style={{width: "100%"}}>
				<thead>
					<tr>
						<th onClick={()=>changeSortFunction("sortByCampaignName")}>
							{sortFunction==="sortByCampaignName" && "⬇️"}
							{sortFunction==="-sortByCampaignName" && "⬆️"}
							Campaign
						</th>
						<th onClick={()=>changeSortFunction("sortByTotalResponses")}>
							{sortFunction==="sortByTotalResponses" && "⬇️"}
							{sortFunction==="-sortByTotalResponses" && "⬆️"}
							Total Responses
						</th>
						<th onClick={()=>changeSortFunction("sortByPreviousMonthResponses")}>
							{sortFunction==="sortByPreviousMonthResponses" && "⬇️"}
							{sortFunction==="-sortByPreviousMonthResponses" && "⬆️"}
							Previous Month Responses
						</th>
						<th onClick={()=>changeSortFunction("sortByCurrentMonthResponses")}>
							{sortFunction==="sortByCurrentMonthResponses" && "⬇️"}
							{sortFunction==="-sortByCurrentMonthResponses" && "⬆️"}
							Current Month Responses
						</th>
						<th onClick={()=>changeSortFunction("sortByCreationTime")}>
							{sortFunction==="sortByCreationTime" && "⬇️"}
							{sortFunction==="-sortByCreationTime" && "⬆️"}
							Creation Date
						</th>
						<th onClick={()=>changeSortFunction("sortByEndTime")}>
							{sortFunction==="sortByEndTime" && "⬇️"}
							{sortFunction==="-sortByEndTime" && "⬆️"}
							End Date
						</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(campaigns) && sortedCampaigns.map((campaign) => {
						return (
							<tr className={campaign.data.deleted && "deleted"} key={campaign.id}>
								<td>
									{campaign.data.name}
									{!campaign.data.deleted && <a href={"https://app.sportovivo.com/"+campaign.id} target="_blank" rel="noreferrer" className="campaign-link">🔗</a>}
									{campaign.data.deleted && <><br />{formatDate(campaign.data.deletedTimestamp._seconds*1000)}</>}
								</td>
								<td>{campaign.data.totalResponses}</td>
								<td>{campaign.data.monthlyResponses?.[previousMonthString]?campaign.data.monthlyResponses?.[previousMonthString]:0}</td>
								<td>{campaign.data.monthlyResponses?.[currentMonthString]?campaign.data.monthlyResponses?.[currentMonthString]:0}</td>
								<td>{campaign.data.timestamp?formatDate(campaign.data.timestamp._seconds*1000):'-'}</td>
								<td>{campaign.data.endDate?formatDate(campaign.data.endDate):'-'}</td>
							</tr>
						)
					})}
				</tbody>
				<tfoot>
					<tr>
						<td><b>Total</b></td>
						<td>{Array.isArray(campaigns) && campaigns.reduce((total, campaign)=>{return total + (campaign.data.totalResponses?campaign.data.totalResponses:0)}, 0)}</td>
						<td>{Array.isArray(campaigns) && campaigns.reduce((total, campaign)=>{return total + (campaign.data?.monthlyResponses?.[previousMonthString]?campaign.data?.monthlyResponses?.[previousMonthString]:0)}, 0)}</td>
						<td>{Array.isArray(campaigns) && campaigns.reduce((total, campaign)=>{return total + (campaign.data?.monthlyResponses?.[currentMonthString]?campaign.data?.monthlyResponses?.[currentMonthString]:0)}, 0)}</td>
						<td>-</td>
						<td>-</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
}

export default UserPage;