import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { sendPhoneVerificationCode, verifyPhoneCode } from "../adminFirebaseActions.mjs";
const RECAPTCHA_CONTAINER_ID = "3be6ac92-2d21-4f31-a2c0-e551d48b2ddf";

function MFAPage() {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneCode, setPhoneCode] = useState("");
	const [verificationId, setVerificationId] = useState("");
	const [pageState, setPageState] = useState("INITIAL");
	const [errorMessage, setErrorMessage] = useState("");
	const navigate = useNavigate();
	function submitPhone(e){
		e.preventDefault();

		setPageState("LOADING");
		sendPhoneVerificationCode(RECAPTCHA_CONTAINER_ID, phoneNumber)
		.then(function(vId){
			setVerificationId(vId);
			setPageState("CODE");
		})
		.catch(function(error){
			console.log("submitPhone error", error, {...error});
		})
	}
	function submitCode(e){
		e.preventDefault();

		setPageState("LOADING");
		verifyPhoneCode(verificationId, phoneCode)
		.then(function(){
			navigate("/", {"replace":true});
		})
		.catch(function(error){
			console.log("submitCode error", error, {...error});
			if(error.code === "auth/invalid-verification-code"){
				setErrorMessage("The code provided was invalid");
				setPageState("CODE");
			}
		})
	}
	return (<>
		<div>You must enable two-factor to be an admin</div>
		{pageState==="LOADING" && (<>Loading...</>)}
		{pageState==="INITIAL" && (<>
			<form onSubmit={submitPhone}>
				<label>
					<span>Enter your phone number in international format:</span>
					<br />
					<input onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} placeholder="eg: +301234567890" type="tel" pattern="\+[0-9]{12,}" required />
				</label>
				<br />
				<input type="submit" value="Send verification code"/>
			</form>
		</>)}
		{pageState==="CODE" && (<>
			<form onSubmit={submitCode}>
				<label>
					<span>Enter the code sent to <b>{phoneNumber}</b>:</span>
					<br />
					<input onChange={(e)=>{setPhoneCode(e.target.value);setErrorMessage("");}} value={phoneCode} required />
				</label>
				<br />
				<input type="submit" value="Submit code"/>
			</form>
			{errorMessage && <span style={{"color":"red"}}>{errorMessage}</span>}
		</>)}
	</>);
}
export default MFAPage;
